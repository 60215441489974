import React, { useEffect, useState } from "react";

function ProgressiveImage({ placeholderSrc, src, cssClass, ...props }) {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);
  return (
    <img
      loading="lazy"
      {...{ src: imgSrc, ...props }}
      alt={props.alt || ""}
      className={cssClass}
    />
  );
}

export default React.memo(ProgressiveImage);
