import { API_CONFIG } from "api/api.config";
import { get, isEmpty } from "lodash";
import CookieService from "services/cookiesServices";
import OneSignal from "react-onesignal";
import { STORAGE_KEYS } from "utils/constants/storage-keys.constant";
import socketIOClient, { io } from "socket.io-client";
import notificationService from "services/notification.service";
function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

function getValue(...param) {
  return get(...param);
}

function checkEmpty(value) {
  return isEmpty(value);
}

function redirectTo(pathName) {
  window.location.href = `${window.location.origin}/${pathName}`;
}
// function removeTextDash(pathName) {

//   return newStr;
// }

const runOneSignal = async () => {
  if (typeof window.OneSignal === "undefined") {
    window.OneSignal = [];
    await OneSignal.init({
      appId: API_CONFIG.APP_NOTIFICATION_ID,
      allowLocalhostAsSecureOrigin: false,
      welcomeNotification: {
        disable: true,
      },
    });
  }
};
const OneSignalUserId = async () => {
  await runOneSignal(); // Initialize OneSignal if it's not already initialized

  return new Promise((resolve, reject) => {
    const userId = OneSignal.getUserId();
    if (userId) {
      console.log(userId, "User ID");

      resolve(userId);
    } else {
      reject(new Error("Failed to retrieve user ID from OneSignal."));
    }
  });
};

const checkHttpUrl = (url) => {
  const checkUrl = url?.includes("https");
  return checkUrl ? url : API_CONFIG.HTTP_URL;
};

const calculateWarrantyYear = (todayDate, expiryDate) => {
  const today = new Date(todayDate);
  const expiry = new Date(expiryDate);

  const diffMilliseconds = expiry - today;

  // Calculate the difference in years, months, weeks, days, hours, and minutes
  const year = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24 * 365.25)); // Account for leap years
  const month = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24 * 30.44)); // Approximate number of days in a month
  const day = Math.ceil(diffMilliseconds / (1000 * 60 * 60 * 24));
  const hour = Math.ceil(diffMilliseconds / (1000 * 60 * 60));
  // const minute = Math.ceil(diffMilliseconds / (1000 * 60));

  if (year >= 1) {
    return {
      year,
      month: 0,
      week: 0,
      day: 0,
      hour: 0,
      // minute: 0,
    };
  } else if (month >= 1) {
    return {
      year: 0,
      month,
      week: 0,
      day: 0,
      hour: 0,
      // minute: 0,
    };
  } else if (day >= 1) {
    return {
      year: 0,
      month: 0,
      week: 0,
      day,
      hour: 0,
      // minute: 0,
    };
  } else {
    return {
      year: 0,
      month: 0,
      week: 0,
      day: 0,
      hour,
      // minutes: 0,
    };
  }
};

const warrantyDuration = (duration) => {
  console.log(duration, "DURATION");
  const [key, value] = Object.entries(duration).find(
    ([key, value]) => value !== 0
  );

  const concatenatedValue = `${value > 0 ? value : "Expired"} ${
    value > 1 ? key + "s" : value < 0 ? "" : key
  }`;
  return concatenatedValue;
};

const normalizeInfiniteQueryData = (data) => {
  return data?.pages?.flatMap((page) => page.data) ?? [];
};

function handleFetchOnScroll({ target }, meta) {
  const { scrollHeight, scrollTop, clientHeight } = target;

  if (scrollHeight - scrollTop <= clientHeight + 10) {
    fetchMore(meta);
  }
}

const fetchMore = (meta) => {
  const { hasNextPage, isFetchingNextPage, fetchNextPage } = meta;

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }
};
function unregisterServiceWorker() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration
        .unregister()
        .then(() => {
          console.log("Service worker unregistered");
        })
        .catch((error) => {
          console.error("Error unregistering service worker:", error);
        });
    });
  }
}
const socketConnection = () => {
  const socket = io(process.env.REACT_APP_HTTP_URL, {
    transports: ["websocket"], // Use only WebSocket transport
    query: {
      EIO: 4,
      auth: `${CookieService.get(STORAGE_KEYS.AUTH_TOKEN)}`,
      // Set the EIO version to 4
    },

    // auth: {
    //   token: `${CookieService.get(STORAGE_KEYS.AUTH_TOKEN)}`,
    // },
  });
  return socket;
};

const clearCache = () => {
  if (typeof window !== "undefined" && "caches" in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
};

const utilService = {
  kFormatter,
  getValue,
  checkEmpty,
  redirectTo,
  runOneSignal,
  OneSignalUserId,
  warrantyDuration,

  checkHttpUrl,
  calculateWarrantyYear,
  normalizeInfiniteQueryData,
  handleFetchOnScroll,
  fetchMore,
  socketConnection,
  unregisterServiceWorker,
  clearCache,
};
export default utilService;
