import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../api/api-call-handler";

export default function useReactQuery({
  queryType,
  method,
  params = {},

  queryOptions = {},
  customApiCallHandler = undefined,
  url,
  queryKey,
  dataNormalizer = undefined,
  useParamsAsQueryIdentifier,
}) {
  const queryFuncParameters = {};

  if (FUNCTIONS_WITH_QUERY_KEY.includes(queryType)) {
    queryFuncParameters.queryKey = useParamsAsQueryIdentifier
      ? [queryKey, params]
      : [queryKey];
  }
  if (queryType === "infinite") {
    queryFuncParameters.select = normalizeInfiniteQueryData;
    queryFuncParameters.getNextPageParam = getNextPageParam;
  }

  let apiCallFunc = async (paramsFromMutation) => {
    const { data } = await request({
      url: url,
      method: method,
      params: paramsFromMutation,

      // params:
      //   queryType === "infinite"
      //     ? {
      //         ...params,
      //         pageNumber: Number(paramsFromMutation?.pageParam ?? 0) + 1,
      //       }
      //     : paramsFromMutation ?? params,
    });

    return dataNormalizer ? dataNormalizer(data) : data;
  };
  queryFuncParameters[FUNCTION_NAMES[queryType]] =
    customApiCallHandler || apiCallFunc;

  return {
    handleFetchOnScroll,
    fetchMore,
    ...QUERY_FUNCTIONS?.[queryType]({
      ...queryFuncParameters,
      staleTime: Infinity,
      ...queryOptions,
      cacheTime: Infinity,
      keepPreviousData: true,
    }),
  };
}

const FUNCTION_NAMES = {
  query: "queryFn",
  mutation: "mutationFn",
  infinite: "queryFn",
};
const QUERY_FUNCTIONS = {
  mutation: useMutation,
  query: useQuery,
  infinite: useInfiniteQuery,
};

function handleFetchOnScroll({ target }, meta) {
  const { scrollHeight, scrollTop, clientHeight } = target;
  if (scrollHeight - scrollTop <= clientHeight + 10) {
    fetchMore(meta);
  }
}

const fetchMore = (meta) => {
  const { hasNextPage, isFetchingNextPage, fetchNextPage } = meta;
  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }
};
const normalizeInfiniteQueryData = (data) => {
  return data?.pages?.flatMap((page) => page.data) ?? [];
};
const getNextPageParam = (lastPage, pages) => {
  return pages.length + 1 <= lastPage.totalPages ? pages.length : undefined;
};
const FUNCTIONS_WITH_QUERY_KEY = ["query", "infinite"];
