import { create } from "apisauce";
import {
  API_CONFIG,
  API_ROUTES,
  CONTENT_TYPE,
  HTTP_STATUS,
} from "../api/api.config";
import AuthService from "./auth.service";
import utilService from "../utils/Helpers/functions.helper";
import LocalStorageService from "./localStorage.services";
import { STORAGE_KEYS } from "utils/constants/storage-keys.constant";
import {
  authenticatedRoutes,
  unauthenticatedRoutes,
} from "utils/constants/routes.constant";
import CookieService from "./cookiesServices";

import { queryClient } from "App";

const { getToken } = AuthService;
const { getValue, checkEmpty } = utilService;

export const BASE_URL = API_CONFIG.BASE_URL_DEV;

const apiSauceInstance = create({
  baseURL: BASE_URL,
});
const ongoingRequests = new Map();

async function callApi(url, method, queryParams, config) {
  const requestKey = generateRequestKey(url, method, queryParams, config);

  if (ongoingRequests.has(requestKey)) {
    return ongoingRequests.get(requestKey);
  }

  const responsePromise = apiSauceInstance[method](url, queryParams, config);
  ongoingRequests.set(requestKey, responsePromise);

  try {
    const response = await responsePromise;
    const handledResponse = handleResponse(response);
    ongoingRequests.delete(requestKey);
    return handledResponse;
  } catch (error) {
    ongoingRequests.delete(requestKey);
    throw error;
  }
}

function generateRequestKey(url, method, queryParams, config) {
  return `${method}-${url}-${JSON.stringify(queryParams)}-${JSON.stringify(
    config
  )}`;
}

function handleResponse(response) {
  const mutatedResponse = {
    ok: response.ok,
    status: response.status,
    response: {
      code: getValue(response.data, "Code", response.status),
      message: getValue(response.data, "Message", response?.problem),
    },
    data: !checkEmpty(response.data) ? response.data : null,
  };

  mutatedResponse.response.message = getError(response);

  return mutatedResponse;
}

apiSauceInstance.addResponseTransform(async (response) => {
  if (
    response.status === HTTP_STATUS.UNAUTHORIZED &&
    !response.config.url.includes(API_ROUTES.AUTH.REFRESH_TOKEN)
  ) {
    const newTokens = await refreshToken(response);
    if (!newTokens?.ok) {
      CookieService.remove("");
      utilService.redirectTo(unauthenticatedRoutes.LOGIN);
    }
  }
});

apiSauceInstance.addAsyncRequestTransform(async (request) => {
  const token = CookieService.get(STORAGE_KEYS.AUTH_TOKEN);
  request.headers["Authorization"] = `BEARER ${token}`;
  request.headers["Content-Type"] = CONTENT_TYPE.JSON;
  // request.headers["Access-Control-Allow-Origin"] = "*";
});

// ReCall api again after refresh token
// async function reCallAfterRefreshToken(newTokens, response) {
//   response.config.headers["Authorization"] = `BEARER ${newTokens?.accessToken}`;

//   try {
//     const res =
//       (await apiSauceInstance.axiosInstance.request(response.config)) ||
//       response;
//     return res ?? null;
//   } catch (e) {
//     CookieService.remove("REFRESH_TOKEN");
//     CookieService.remove("AUTH_TOKEN");
//     CookieService.remove("USER_INFO");
//     utilService.redirectTo(unauthenticatedRoutes.LOGIN);
//   }
// }

// Refresh token request
async function refreshToken() {
  let tokens = CookieService.get(STORAGE_KEYS.REFRESH_TOKEN);
  if (tokens) {
    // tokens = JSON.parse(tokens);
    const response =
      (await callApi(API_ROUTES.AUTH.REFRESH_TOKEN, API_CONFIG.POST, {
        refreshToken: tokens,
      })) || {};
    if (response?.data) {
      console.log(response, "DATTAAAA");
      CookieService.set(STORAGE_KEYS.AUTH_TOKEN, response.data.accessToken);
      CookieService.set(STORAGE_KEYS.REFRESH_TOKEN, response.data.refreshToken);
    }
    return response;
  }
  return {};
}

function getError({ problem, data, status }) {
  // alert("pppppppp");

  return RESPONSE_TYPES[problem] ?? status == "401" ? "" : data?.message;
}

// function handleErrors(errors) {
//   return errors ? Object.values(errors) : "Something went wrong";
// }

const RESPONSE_TYPES = {
  NETWORK_ERROR: ["Network not available"],
  SERVER_ERROR: ["Something went wrong"],
  TIMEOUT_ERROR: ["Server didn't respond in time"],
};

export default callApi;
