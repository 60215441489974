import React, { memo } from "react";
import "./CustomHeading.less";

const CustomHeading = (props) => {
  const { type,className="" } = props;
  let headingClass;

  switch (type) {
    case "secondary":
      headingClass = "second-heading";
      break;

    default:
      headingClass = "primary-heading";
      break;
  }

  return (
    <span className="kl-custom-heading">
      <h5 {...props} className={`${headingClass} ${className}`}>
        {props.children}
      </h5>
    </span>
  );
};

export default memo(CustomHeading);
