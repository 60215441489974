import DashboardIcon from "assets/all-clevis-svg-files/dashboardicon";
import NewsManagementIcon from "assets/all-clevis-svg-files/newsmanagementicon";
import PackageManagementIcon from "assets/all-clevis-svg-files/packagemanagementicon";
import SettingsIcon from "assets/all-clevis-svg-files/settingsicon";
import UserManagementIcon from "assets/all-clevis-svg-files/usermanagementicon";

export const authenticatedRoutes = {
  ADMIN_DASHBOARD: "",
  USER_MANAGEMENT: "/user-management",
  PACKAGES_MANAGEMENT: "/packages-management",
  NEWS_MANAGEMENT: "/news-management",
  ACCOUNT_SETTINGS: "/settings",
  RESET_USER_PASSWORD: "/reset-user-password",
  NOTIFICATIONS: "/notifications",
};
export const unauthenticatedRoutes = {
  LOGIN: "login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  CHECK_EMAIL: "/check-email",
  TERMS_CONDITION: "/terms-condition",
  PRIVACY_POLICY: "/privacy-policy",
};
export const AllMenusItems = [
  {
    label: "Dashboard",
    linkTo: authenticatedRoutes.ADMIN_DASHBOARD,
    selectedOptionKey: "",
    icon: <DashboardIcon />,
  },
  {
    label: "User Management",
    linkTo: authenticatedRoutes.USER_MANAGEMENT,
    selectedOptionKey: "user-management",
    icon: <UserManagementIcon />,
  },
  {
    label: "Packages Management",
    linkTo: authenticatedRoutes.PACKAGES_MANAGEMENT,
    selectedOptionKey: "packages-management",
    icon: <PackageManagementIcon />,
  },
  {
    label: "News Management",
    linkTo: authenticatedRoutes.NEWS_MANAGEMENT,
    selectedOptionKey: "news-management",
    icon: <NewsManagementIcon />,
  },
  {
    label: "Account Settings",
    linkTo: authenticatedRoutes.ACCOUNT_SETTINGS,
    selectedOptionKey: "settings",
    icon: <SettingsIcon />,
  },
];
