import React from "react";

const Dashboard = React.lazy(() => import("../../src/screens/dashboard"));
const UserManagment = React.lazy(() =>
  import("../../src/screens/UserManagement")
);
const NewsManagment = React.lazy(() =>
  import("../../src/screens/NewsManagement")
);
const PackagesMangement = React.lazy(() =>
  import("../../src/screens/PackagesManagement")
);
const AccountSetting = React.lazy(() =>
  import("../../src/screens/AccountSettings")
);
const Notifications = React.lazy(() =>
  import("../../src/screens/Notifications")
);

export {
  Dashboard,
  UserManagment,
  NewsManagment,
  PackagesMangement,
  AccountSetting,
  Notifications,
};
