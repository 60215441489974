import { Form } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "components/uiComponents/CustomButton/CustomButton";
import CustomForm from "components/uiComponents/CustomForm/CustomForm";
import CustomFormItem from "components/uiComponents/CustomFormItem/CustomFormItem";
import CustomHeading from "components/uiComponents/CustomHeading/CustomHeading";
import CustomInput from "components/uiComponents/CustomInput/CustomInput";
import logo from "../../../assets/images/Logo.png";
import "../../auth/auth.less";
import useReactQuery from "hooks/useReactQuery";
import { API_CONFIG, API_ROUTES } from "api/api.config";
import { authenticatedRoutes, unauthenticatedRoutes } from "utils/constants/routes.constant";
import utilService from "utils/Helpers/functions.helper";

function ForgotPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
const [email,setEmail]=useState("")
  const onSuccess =(data )=>{
    const payload={...data,
    email:email
    }
navigate(unauthenticatedRoutes.CHECK_EMAIL, { state: payload })
      }
  const onFinish = (payload) => {
    setEmail(payload?.email)
    mutate(payload)
  };

  const {mutate,isLoading }=useReactQuery({
    queryType:"mutation",
    url:API_ROUTES.AUTH.FORGOT_PASSWORD,
    method:API_CONFIG.POST,
    queryOptions:{onSuccess}
  })
  return (
    <div className="auth-sec login">
      <div className="container">
        <div className="auth-screen">
          <div className="auth-head">
            <h2>Forgot Password</h2>
          </div>
          <CustomForm
            initialValues={{ remember: true }}
            name="basic"
            form={form}
            onFinish={onFinish}
            className="gx-signin-form gx-form-row0"
          >
            <CustomFormItem
              required={true}
              name="email"
              label="Email Address"
              className="hide-form-label"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Email address is required",
                },
                { type: "email",message:"Email is not valid" },
              
              ]}
            >
              <CustomInput placeholder="Enter Email Address" />
            </CustomFormItem>
            <p>Provide registered email address to receive OTP</p>

            <CustomFormItem>
              <CustomFormItem>
                <CustomButton
                  type="primary"
                  className="gx-mb-0 btn"
                  htmlType="submit"
                  loading={isLoading}
                  // onClick={handleClick}
                >
                  Send OTP
                </CustomButton>
              </CustomFormItem>
            </CustomFormItem>
          </CustomForm>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
