import React from "react";

function DashboardIcon() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.22222 0.5H0.888889C0.653141 0.5 0.427048 0.599504 0.260349 0.776621C0.0936505 0.953739 0 1.19396 0 1.44444V7.11111C0 7.36159 0.0936505 7.60182 0.260349 7.77893C0.427048 7.95605 0.653141 8.05556 0.888889 8.05556H6.22222C6.45797 8.05556 6.68406 7.95605 6.85076 7.77893C7.01746 7.60182 7.11111 7.36159 7.11111 7.11111V1.44444C7.11111 1.19396 7.01746 0.953739 6.85076 0.776621C6.68406 0.599504 6.45797 0.5 6.22222 0.5ZM5.33333 6.16667H1.77778V2.38889H5.33333V6.16667ZM9.77778 8.05556H15.1111C15.3469 8.05556 15.573 7.95605 15.7397 7.77893C15.9064 7.60182 16 7.36159 16 7.11111V1.44444C16 1.19396 15.9064 0.953739 15.7397 0.776621C15.573 0.599504 15.3469 0.5 15.1111 0.5H9.77778C9.54203 0.5 9.31594 0.599504 9.14924 0.776621C8.98254 0.953739 8.88889 1.19396 8.88889 1.44444V7.11111C8.88889 7.36159 8.98254 7.60182 9.14924 7.77893C9.31594 7.95605 9.54203 8.05556 9.77778 8.05556ZM10.6667 2.38889H14.2222V6.16667H10.6667V2.38889ZM0 16.5556C0 16.806 0.0936505 17.0463 0.260349 17.2234C0.427048 17.4005 0.653141 17.5 0.888889 17.5H6.22222C6.45797 17.5 6.68406 17.4005 6.85076 17.2234C7.01746 17.0463 7.11111 16.806 7.11111 16.5556V10.8889C7.11111 10.6384 7.01746 10.3982 6.85076 10.2211C6.68406 10.0439 6.45797 9.94444 6.22222 9.94444H0.888889C0.653141 9.94444 0.427048 10.0439 0.260349 10.2211C0.0936505 10.3982 0 10.6384 0 10.8889V16.5556ZM1.77778 11.8333H5.33333V15.6111H1.77778V11.8333ZM8.88889 16.5556C8.88889 16.806 8.98254 17.0463 9.14924 17.2234C9.31594 17.4005 9.54203 17.5 9.77778 17.5H15.1111C15.3469 17.5 15.573 17.4005 15.7397 17.2234C15.9064 17.0463 16 16.806 16 16.5556V10.8889C16 10.6384 15.9064 10.3982 15.7397 10.2211C15.573 10.0439 15.3469 9.94444 15.1111 9.94444H9.77778C9.54203 9.94444 9.31594 10.0439 9.14924 10.2211C8.98254 10.3982 8.88889 10.6384 8.88889 10.8889V16.5556ZM10.6667 11.8333H14.2222V15.6111H10.6667V11.8333Z"
        fill="#77d0a7"
      />
    </svg>
  );
}

export default DashboardIcon;
