import React from "react";

function UserManagementIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99938 0.000335693C6.62202 0.0306539 4.35057 0.988535 2.66938 2.66972C0.988199 4.3509 0.0303182 6.62236 0 8.99972C0.0111132 10.3846 0.341725 11.7483 0.966094 12.9846C1.59046 14.2208 2.49174 15.2963 3.59975 16.1272V16.1992H3.68975C5.21351 17.3669 7.07968 17.9997 8.99938 17.9997C10.9191 17.9997 12.7853 17.3669 14.309 16.1992H14.399V16.1272C15.507 15.2963 16.4083 14.2208 17.0327 12.9846C17.657 11.7483 17.9877 10.3846 17.9988 8.99972C17.9685 6.62236 17.0106 4.3509 15.3294 2.66972C13.6482 0.988535 11.3767 0.0306539 8.99938 0.000335693ZM5.46263 15.2363C5.594 14.6346 5.92713 14.096 6.40674 13.7097C6.88634 13.3234 7.48361 13.1126 8.09945 13.1124H9.89932C10.5152 13.1126 11.1124 13.3234 11.592 13.7097C12.0716 14.096 12.4048 14.6346 12.5361 15.2363C11.4642 15.867 10.2431 16.1996 8.99938 16.1996C7.75567 16.1996 6.53457 15.867 5.46263 15.2363ZM14.048 14.0754C13.7057 13.2573 13.1293 12.5586 12.3912 12.067C11.6531 11.5755 10.7861 11.313 9.89932 11.3126H8.09945C7.21263 11.313 6.34571 11.5755 5.60759 12.067C4.86946 12.5586 4.29304 13.2573 3.95073 14.0754C3.27637 13.4121 2.73932 12.6225 2.37027 11.7516C2.00122 10.8807 1.80739 9.94556 1.79988 8.99972C1.82322 7.09754 2.58923 5.27985 3.93437 3.93471C5.27952 2.58957 7.09721 1.82355 8.99938 1.80021C10.9016 1.82355 12.7193 2.58957 14.0644 3.93471C15.4095 5.27985 16.1755 7.09754 16.1989 8.99972C16.1914 9.94556 15.9976 10.8807 15.6285 11.7516C15.2594 12.6225 14.7224 13.4121 14.048 14.0754Z"
        fill="#77d0a7"
      />
      <path
        d="M8.99939 3.60008C8.52363 3.58899 8.05057 3.67453 7.60882 3.85151C7.16707 4.02849 6.7658 4.29324 6.4293 4.62974C6.0928 4.96624 5.82805 5.36751 5.65107 5.80926C5.47409 6.25101 5.38855 6.72407 5.39964 7.19983C5.38855 7.67559 5.47409 8.14865 5.65107 8.5904C5.82805 9.03215 6.0928 9.43341 6.4293 9.76992C6.7658 10.1064 7.16707 10.3712 7.60882 10.5481C8.05057 10.7251 8.52363 10.8107 8.99939 10.7996C9.47515 10.8107 9.94821 10.7251 10.39 10.5481C10.8317 10.3712 11.233 10.1064 11.5695 9.76992C11.906 9.43341 12.1707 9.03215 12.3477 8.5904C12.5247 8.14865 12.6102 7.67559 12.5991 7.19983C12.6102 6.72407 12.5247 6.25101 12.3477 5.80926C12.1707 5.36751 11.906 4.96624 11.5695 4.62974C11.233 4.29324 10.8317 4.02849 10.39 3.85151C9.94821 3.67453 9.47515 3.58899 8.99939 3.60008ZM8.99939 8.99971C8.75996 9.01128 8.52076 8.97265 8.29715 8.88629C8.07353 8.79993 7.87046 8.66776 7.70096 8.49826C7.53146 8.32876 7.39928 8.12568 7.31293 7.90207C7.22657 7.67846 7.18794 7.43926 7.19951 7.19983C7.18794 6.9604 7.22657 6.7212 7.31293 6.49759C7.39928 6.27397 7.53146 6.07089 7.70096 5.9014C7.87046 5.7319 8.07353 5.59972 8.29715 5.51337C8.52076 5.42701 8.75996 5.38838 8.99939 5.39995C9.23882 5.38838 9.47802 5.42701 9.70163 5.51337C9.92525 5.59972 10.1283 5.7319 10.2978 5.9014C10.4673 6.07089 10.5995 6.27397 10.6859 6.49759C10.7722 6.7212 10.8108 6.9604 10.7993 7.19983C10.8108 7.43926 10.7722 7.67846 10.6859 7.90207C10.5995 8.12568 10.4673 8.32876 10.2978 8.49826C10.1283 8.66776 9.92525 8.79993 9.70163 8.88629C9.47802 8.97265 9.23882 9.01128 8.99939 8.99971Z"
        fill="#77d0a7"
      />
    </svg>
  );
}

export default UserManagementIcon;
