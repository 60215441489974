import { RouterProvider } from "react-router-dom";

import "assets/vendors/style";
import "./App.less";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense, useEffect } from "react";

import {
  AuthenticatedRouter,
  UnauthenticatedRouter,
} from "routes/routing-options";

import AuthService from "services/auth.service";
import OneSignal from "react-onesignal";
import utilService from "utils/Helpers/functions.helper";
import { API_CONFIG } from "api/api.config";
import FullPageLoader from "components/customeComponents/FullPageLoader/fullPageLoader";
import { useState } from "react";
import notificationService from "services/notification.service";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
    mutations: {
      retry: 2,
    },
  },
});
const isAuthenticated = AuthService.getToken();
// const [playerId,setPlayerId]=useState(CookieService.get(STORAGE_KEYS.PLAYER_ID))

function App() {
  const socket = utilService.socketConnection();
  useEffect(() => {
    // utilService.runOneSignal();

    utilService.clearCache();
  }, []);

  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, []);
  return (
    // <Suspense fallback={<div>...loading </div>}>
    <QueryClientProvider client={queryClient}>
      <RouterProvider
        router={isAuthenticated ? AuthenticatedRouter : UnauthenticatedRouter}
      />
    </QueryClientProvider>
    // </Suspense>
  );
}

export default App;
