import callApi from "../services/api.service";
import NotificationService from "../services/notification.service";

export async function request({
  url, //Service url
  method, //Web Service type 'post,get,put,delete....'
  params, //Paramter for request
  config, //APIrequest Configuration
  showToast = true,
}) {
  const response = (await callApi(url, method, params, config)) || {};

  if (response.ok) {
    (response?.data?.message || response?.message) &&
      setTimeout(() => {
        NotificationService.success(
          response?.data?.message || response?.message
        );
      }, 600);
    return response;
  } else {
    response?.response?.message &&
      showToast &&
      NotificationService.error(response?.data?.message);
    // return null;
    throw new Error(response?.response?.message);
  }
}
