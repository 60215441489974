import { Input } from "antd";
import React, { memo } from "react";
import "./CustomInput.less";

const CustomInput = (props) => {
  return (
    <span className="kl-custom-input-container">
      <Input autoComplete="off" {...props} />
    </span>
  );
};

export default memo(CustomInput);
