import { Form, Modal } from "antd";
import { React } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "components/uiComponents/CustomButton/CustomButton";
import CustomForm from "components/uiComponents/CustomForm/CustomForm";
import CustomFormItem from "components/uiComponents/CustomFormItem/CustomFormItem";
import CustomPassword from "components/uiComponents/CustomPassword/CustomPassword";
import "../../auth/auth.less";
import { API_CONFIG, API_ROUTES } from "api/api.config";
import useReactQuery from "hooks/useReactQuery";
import { unauthenticatedRoutes } from "utils/constants/routes.constant";
import CookieService from "services/cookiesServices";
import { STORAGE_KEYS } from "utils/constants/storage-keys.constant";

const { info } = Modal;

function ResetPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  let { token } = location.state;
  const onFinish = (payload) => {
 
const data={
  password:payload.confirmpassword,
  token:token

}

    mutate(data)
  };

  const showPromiseConfirm = (res) => {
    info({
      width: "420px",
      cancelButtonProps: null,
      icon: (
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faCheck} />
        </div>
      ),
      content: "Password has been reset successfully!",
      okText: "Go back to Login",
      cancelText: null,
      async onOk(payload) {
        navigate(unauthenticatedRoutes.LOGIN)
      },
    });
  };

  const onSuccess =(data)=>{
    showPromiseConfirm()
 
      }

  const {mutate,isLoading }=useReactQuery({
    queryType:"mutation",
    url:API_ROUTES.AUTH.RESET_PASSWORD,
    method:API_CONFIG.POST,
    queryOptions:{onSuccess}
  })


  return (
    <div className="auth-sec register">
      <div className="container">
        <div className="auth-screen">
          <div className="auth-head">
            <h2>Reset Password</h2>
          </div>

          <CustomForm
            initialValues={{ remember: true }}
            name="basic"
            form={form}
            onFinish={onFinish}
            className="gx-signin-form gx-form-row0"
          >
            <CustomFormItem
              required={false}
              validateTrigger="onBlur"
              className="hide-form-label"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "New password is required.",
                },
                {
                  max: 100,
                },
                {
                  min: 6,
                  message: "New password must be at least 6 characters.",
                },
              ]}
              name="newpassword"
            >
              <CustomPassword
                type="password"
                placeholder="Enter New Password"
              />
            </CustomFormItem>

            <CustomFormItem
              required={false}
              validateTrigger="onBlur"
              className="hide-form-label"
              label="Retype Password"
              rules={[
                {
                  required: true,
                  message: "Confirm password is required.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newpassword") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "Password does not match"
                      )
                    );
                  },
                }),
              ]}
              name="confirmpassword"
            >
              <CustomPassword
                type="password"
                placeholder="Retype New Password"
              />
            </CustomFormItem>

            <CustomFormItem>
              <CustomFormItem>
                <CustomButton
                  type="primary"
                  className="gx-mb-0 btn"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Reset Password
                </CustomButton>
              </CustomFormItem>
            </CustomFormItem>
          </CustomForm>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
