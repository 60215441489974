import { Form } from "antd";
import React, { memo } from "react";
import "./CustomForm.less";

const CustomForm = (props) => {
  return (
    <div className="custom-form-container">
      <Form {...props}>{props.children}</Form>
    </div>
  );
};

export default memo(CustomForm);
