import { STORAGE_KEYS } from "../utils/constants/storage-keys.constant";
import CookieService from "./cookiesServices";
import LocalStorageService from "./localStorage.services";

function getToken() {
  // return LocalStorageService.get(STORAGE_KEYS.AUTH_TOKEN);
  return CookieService.get(STORAGE_KEYS.AUTH_TOKEN);
}
function getPlayerId() {
  return CookieService.get(STORAGE_KEYS.PLAYER_ID);
}

const AuthService = {
  getToken,
  getPlayerId,
};

export default AuthService;
