import React from "react";

function PackageManagementIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0H2C0.897 0 0 0.897 0 2V16C0 17.103 0.897 18 2 18H18C19.103 18 20 17.103 20 16V2C20 0.897 19.103 0 18 0ZM2 16V2H18L18.002 16H2Z"
        fill="#77d0a7"
      />
      <path d="M4 4H16V6H4V4ZM4 8H16V10H4V8ZM4 12H10V14H4V12Z" fill="#77d0a7" />
    </svg>
  );
}

export default PackageManagementIcon;
