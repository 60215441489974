import { Layout, Menu, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/images/Logo.png";
import "./sidebar.less";
import React, { useState } from "react";
import { AllMenusItems, authenticatedRoutes, unauthenticatedRoutes } from "utils/constants/routes.constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomScrollbars from "components/customeComponents/CustomScrollbars";
import CookieService from "services/cookiesServices";
import { STORAGE_KEYS } from "utils/constants/storage-keys.constant";
import utilService from "utils/Helpers/functions.helper";
import notificationService from "services/notification.service";
import { API_CONFIG, API_ROUTES } from "api/api.config";
import useReactQuery from "hooks/useReactQuery";

const { Sider } = Layout;
const { confirm } = Modal;
const Sidebar = () => {
  const navigate = useNavigate();
  const {pathname }= useLocation()
  const [collapsed, setCollapsed] = useState(false);

  // const socket=  utilService.socketConnection()



  const logout_success = async()=>{
    await CookieService.remove(STORAGE_KEYS.AUTH_TOKEN)
    await CookieService.remove(STORAGE_KEYS.REFRESH_TOKEN)
    await CookieService.remove(STORAGE_KEYS.USER_INFO)
    // await CookieService.remove(STORAGE_KEYS.NOTIFICATION_COUNT)
    utilService.redirectTo(`${unauthenticatedRoutes.LOGIN}`)
    utilService.unregisterServiceWorker()
  
  
    // socket.disconnected()
    
  }
  const { mutate: logout } = useReactQuery({
    queryType: "mutation",
    method: API_CONFIG.DELETE,
    // params: { id: 2 },
    url: API_ROUTES.AUTH.LOGOUT,
    queryOptions: { onSuccess: logout_success },
  });
  
  const showPromiseConfirm = (res) => {
    confirm({

      width: "455px",
      icon: (
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faSignOutAlt} />
        </div>
      ),
      content: "Are you sure you want to Logout?",
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        logout()
        // notificationService.success("User logout successfully")
      },
    });
  };
 

  return (
    <div   className="sidebar-sec">
      <Sider
     
        breakpoint="sm"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {   setCollapsed(collapsed);
          // console.log(collapsed, type);
        }}
        width="244px"
      >
        <div className="logo">
        {!collapsed &&  <a href={authenticatedRoutes.ADMIN_DASHBOARD}>
            <img src={Logo} className="imgOnLogin" alt="Image" />
          </a>}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar ">
          <Menu theme="dark" inlineCollapsed={collapsed}  selectedKeys={[pathname =="/" ? "":pathname]} defaultSelectedKeys={[""]} className="custom-sidebar" mode="inline">
            {AllMenusItems.map((menu,ind) => (
              <Menu.Item tabIndex={ind} className={pathname ==`/${menu.selectedOptionKey}` ? "ant-menu-item-selected" : "" }   key={menu.selectedOptionKey} >
                <Link to={menu?.linkTo}>
                  {/* {menu?.icon && <i className={`kl-sidebar-icon ${menu.icon}`} />} */}
                  {menu?.icon && menu.icon} 

                  {!collapsed && <span >{menu.label}</span>}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </CustomScrollbars>
        <Menu className="kl-sidebar-logout-sec">
          <Menu.Item key={"logout"} className="kl-sidebar-logout">
            <Link
              to="#"
              className="kl-sidebar-link"
              key={0}
              onClick={async () => {
                showPromiseConfirm();
              
              }}
              // onClick={() => {
              //   LocalStorageService.remove(STORAGE_CONST.USER_INFO);
              //   utilService.redirectTo(unAuthenticatedRoutes.LOGIN);
              // }}
            >
              <span className="kl-logout-icon">
                <span className="path3">
                  <svg
                    width="13.355"
                    height="12.019"
                    viewBox="0 0 13.355 12.019"
                  >
                    <path
                      id="Path_6952"
                      data-name="Path 6952"
                      d="M12.016,5.671l-.941.941L12.8,8.342H6.006V9.677H12.8L11.074,11.4l.941.948L15.355,9.01ZM3.335,4.335H8.677V3H3.335A1.339,1.339,0,0,0,2,4.335v9.348a1.339,1.339,0,0,0,1.335,1.335H8.677V13.684H3.335Z"
                      transform="translate(-2 -3)"
                      fill="#fff"
                    />
                  </svg>
                </span>
              </span>
              {!collapsed &&  <span className="text">Logout</span> }
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
};
export default Sidebar;
