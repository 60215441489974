export const API_CONFIG = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
  VERSION: "/v1",
  BASE_URL_QA: process.env.REACT_APP_BASE_URL_QA,
  BASE_URL_DEV: process.env.REACT_APP_BASE_URL_DEV,
  BASE_URL_PROD: process.env.REACT_APP_BASE_URL_PROD,
  HTTP_URL: process.env.REACT_APP_HTTP_URL,
  APP_NOTIFICATION_ID: process.env.REACT_APP_NOTIFICATION_ID,
};

const CONTROLLERS = {
  authController: "auth",
  productController: "product",
  packageController: "subscription-package",
  userController: "user",
  newsController: "news",
  mediaController: "media",
  productTypeController: "product-type",
  notificationController: "notification",
};

export const API_ROUTES = {
  AUTH: {
    LOGIN: `${CONTROLLERS.authController}/login`,
    FORGOT_PASSWORD: `${CONTROLLERS.authController}/forget-password`,
    RESET_PASSWORD: `${CONTROLLERS.authController}/reset-password`,
    CHECK_EMAIL: `${CONTROLLERS.authController}/forget-password/verification`,
    REFRESH_TOKEN: `${CONTROLLERS.authController}/refresh-access-token`,
    RESEND_OTP: `${CONTROLLERS.authController}/resend-otp`,
    LOGOUT: `${CONTROLLERS.authController}/logout`,
  },
  PRODUCT: {
    LIST_ALL_PRODUCTS: `${CONTROLLERS.productController}`,
    VIEW_PRODUCT: `${CONTROLLERS.productController}/{id}`,
    PRODUCT_STATS: `${CONTROLLERS.productController}/dashboard-stats`,
  },

  SUBSCRIPTION_PACKAGE: {
    PACKAGE_LISTING: `${CONTROLLERS.packageController}`,
    ADD_PACKAGE: `${CONTROLLERS.packageController}`,
    UPDATE_PACKAGE: `${CONTROLLERS.packageController}/{id}`,
    TENURE_TYPE: `${CONTROLLERS.packageController}/tenure-types`,
    PACKAGE_STATUS: `${CONTROLLERS.packageController}/{id}/status`,
    PACKAGE_DELETE: `${CONTROLLERS.packageController}/{id}`,
  },
  USER: {
    UPDATE_USER_SETTINGS: `${CONTROLLERS.userController}/settings`,
    USER_ME: `${CONTROLLERS.userController}/me`,
    CHANGE_PASSWORD: `${CONTROLLERS.userController}/change-password`,
    USER_LISTING: `${CONTROLLERS.userController}`,
    USER_STATUS: `${CONTROLLERS.userController}/{id}/status`,
    USER_STATS: `${CONTROLLERS.userController}/dashboard-stats`,
  },
  NEWS: {
    ARTICLE_LISTING: `${CONTROLLERS.newsController}`,
    ARTICLE_EDIT: `${CONTROLLERS.newsController}/{id}`,
    ARTICLE_DELETE: `${CONTROLLERS.newsController}/{id}`,
    ARTICLE_ADD_NEW: `${CONTROLLERS.newsController}`,
    ARTICLE_STATUS: `${CONTROLLERS.newsController}/{id}/status`,
  },
  MEDIA: {
    UPLOAD_IMAGE: `${CONTROLLERS.mediaController}/upload-image`,
  },
  PRODUCT_TYPE: {
    PRODUCT: `${CONTROLLERS.productTypeController}`,
  },
  NOTIFICATION: {
    NOTIFICATION_LISTING: `${CONTROLLERS.notificationController}/me`,
  },
};

export const CONTENT_TYPE = {
  JSON: "application/json",
  FORM_DATA: "multipart/form-data",
};

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  OK: 200,
  CREATED: 201,
  PAYLOAD_TOO_LARGE: 413,
  SERVER_ERROR: 500,
};

export const STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  HIDE: "HIDE",
  PUBLISHED: "PUBLISHED",
};
