const STORAGE_KEYS = {
  AUTH_TOKEN: "AUTH_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  USER_INFO: "USER_INFO",
  PLAYER_ID: "PLAYER_ID",
  NOTIFICATION_COUNT: "NOTIFICATION_COUNT",
  NOTIFICATION: "NOTIFICATION",
};
const REACT_QUERY_KEYS = {
  PRODUCTS_LISTING: "PRODUCTS_LISTING",
  TENURE_TYPE: "TENURE_TYPE",
  PACKAGES: "PACKAGES",
  USER_LISTING: "USER_LISTING",
  NEWS_LISTING: "NEWS_LISTING",
  PRODUCT_TYPE: "PRODUCT_TYPE",
  USER_STATS: "USER_STATS",
  PRODUCT_STATS: "PRODUCT_STATS",
  ACCOUNT_INFO: "ACCOUNT_INFO",
};

export { STORAGE_KEYS, REACT_QUERY_KEYS };
