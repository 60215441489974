import React from "react";

function NotificationIcons() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7778 12.7446V8.8C17.7778 5.2613 15.35 2.2803 12.0611 1.3838C11.7356 0.572 10.94 0 10 0C9.06 0 8.26445 0.572 7.93889 1.3838C4.65 2.2814 2.22222 5.2613 2.22222 8.8V12.7446L0.325557 14.6223C0.222176 14.7243 0.14019 14.8455 0.0843171 14.979C0.0284446 15.1124 -0.000210499 15.2555 1.16408e-06 15.4V17.6C1.16408e-06 17.8917 0.117064 18.1715 0.325438 18.3778C0.533812 18.5841 0.816427 18.7 1.11111 18.7H18.8889C19.1836 18.7 19.4662 18.5841 19.6746 18.3778C19.8829 18.1715 20 17.8917 20 17.6V15.4C20.0002 15.2555 19.9716 15.1124 19.9157 14.979C19.8598 14.8455 19.7778 14.7243 19.6744 14.6223L17.7778 12.7446ZM17.7778 16.5H2.22222V15.8554L4.11889 13.9777C4.22227 13.8757 4.30426 13.7545 4.36013 13.621C4.416 13.4876 4.44466 13.3445 4.44445 13.2V8.8C4.44445 5.7673 6.93667 3.3 10 3.3C13.0633 3.3 15.5556 5.7673 15.5556 8.8V13.2C15.5556 13.4926 15.6722 13.772 15.8811 13.9777L17.7778 15.8554V16.5ZM10 22C10.6881 22.0009 11.3594 21.7895 11.9205 21.3952C12.4817 21.0009 12.9048 20.4433 13.1311 19.8H6.86889C7.09518 20.4433 7.51831 21.0009 8.07946 21.3952C8.6406 21.7895 9.31189 22.0009 10 22Z"
        fill="#009345"
      />
    </svg>
  );
}

export default NotificationIcons;
