import MainLayout from "layout";
import {
  authenticatedRoutes,
  unauthenticatedRoutes,
} from "utils/constants/routes.constant";
import {
  UserManagment,
  Dashboard,
  AccountSetting,
  PackagesMangement,
  NewsManagment,
  Notifications,
} from "./lazy-import";
import {
  Login,
  ForgotPassword,
  ResetPassword,
  CheckEmail,
} from "screens/auth/index";
import { Navigate, createBrowserRouter } from "react-router-dom";
import PrivacyPolicy from "screens/AccountSettings/PrivacyPolicy";
import TermsCondition from "screens/AccountSettings/TermsCondition";

export const AuthenticatedRouter = createBrowserRouter([
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        index: true,
        // path: authenticatedRoutes.ADMIN_DASHBOARD,

        element: <Dashboard />,
      },
      {
        path: authenticatedRoutes.USER_MANAGEMENT,
        element: <UserManagment />,
      },
      {
        path: authenticatedRoutes.ACCOUNT_SETTINGS,
        element: <AccountSetting />,
      },
      {
        path: authenticatedRoutes.PACKAGES_MANAGEMENT,
        element: <PackagesMangement />,
      },
      {
        path: authenticatedRoutes.NEWS_MANAGEMENT,
        element: <NewsManagment />,
      },
      {
        path: authenticatedRoutes.NOTIFICATIONS,
        element: <Notifications />,
      },
    ],
  },
  { path: "*", element: <Navigate to={authenticatedRoutes.ADMIN_DASHBOARD} /> },
]);

export const UnauthenticatedRouter = createBrowserRouter([
  {
    path: unauthenticatedRoutes.LOGIN,
    element: <Login />,
  },
  {
    path: unauthenticatedRoutes.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: unauthenticatedRoutes.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: unauthenticatedRoutes.CHECK_EMAIL,
    element: <CheckEmail />,
  },
  {
    path: unauthenticatedRoutes.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: unauthenticatedRoutes.TERMS_CONDITION,
    element: <TermsCondition />,
  },

  { path: "*", element: <Navigate to={unauthenticatedRoutes.LOGIN} /> },
]);
