import { Layout } from "antd";
import React, { Suspense, createContext } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import Topheader from "./topheader";
import OneSignal from "react-onesignal";
import { useEffect } from "react";
import FullPageLoader from "components/customeComponents/FullPageLoader/fullPageLoader";
import utilService from "utils/Helpers/functions.helper";
import { useState } from "react";
const { Content } = Layout;
// import OneSignal from "react-onesignal";
// import { useEffect } from "react";
function MainLayout() {
  const [check, setCheck] = useState("");
  // useEffect(()=>{
  //   OneSignal.on('notificationDisplay', function(event) {
  //     // Access the displayed notification
  //     const notification = event.data;
  //     //  alert("chl rha hai")
  //     // Handle the displayed notification
  //     console.log('Notification displayed:', notification);
  //     // Add your custom logic here
  //   });
  // },[])
  const socket = utilService.socketConnection();

  useEffect(() => {
    socket.on("connect", () => {
      setCheck(true);
      // console.log("ID : ", socket.id);
    });
  }, []);
  return (
    <div className="layout-sec">
      <Layout>
        <Sidebar />
        {check ? (
          <Layout>
            <Topheader socket={socket} />
            <Content className="main-content-wrapper">
              <Suspense fallback={<FullPageLoader size={"large"} />}>
                <Outlet />
              </Suspense>
            </Content>
          </Layout>
        ) : (
          <div className="ant-center">
            {" "}
            <FullPageLoader size={"large"} />
          </div>
        )}
      </Layout>
    </div>
  );
}

export default MainLayout;
