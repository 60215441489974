import { Spin } from "antd";
import React from "react";
import "./FullPageLoader.less";

function FullPageLoader({ tip, size, className = "", indicator }) {
  return (
    <React.Fragment>
      <div className="Spin-container">
        <Spin indicator={indicator} size={size} tip={tip} spinning />
      </div>
    </React.Fragment>
  );
}

export default FullPageLoader;
