import React, { memo } from "react";
import { Button } from "antd";
import "./CustomButton.less";

const CustomButton = (props) => {
  return (
    <Button
      {...props}
      className={` ${
        props?.className ? props?.className : ""
      } gx-mb-0 btnStyling`}
    >
      {props.children}
    </Button>
  );
};
export default memo(CustomButton);
