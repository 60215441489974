import React from "react";

function NewsManagementIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9444 8.05556H15.1111V1.44444C15.1111 1.19396 15.0116 0.953739 14.8345 0.776621C14.6574 0.599504 14.4171 0.5 14.1667 0.5H0.944444C0.693962 0.5 0.453739 0.599504 0.276621 0.776621C0.0995036 0.953739 0 1.19396 0 1.44444V14.6667C0 16.2288 1.27122 17.5 2.83333 17.5H16.0556C17.6177 17.5 18.8889 16.2288 18.8889 14.6667V9C18.8889 8.74952 18.7894 8.50929 18.6123 8.33218C18.4352 8.15506 18.1949 8.05556 17.9444 8.05556ZM2.83333 15.6111C2.58285 15.6111 2.34263 15.5116 2.16551 15.3345C1.98839 15.1574 1.88889 14.9171 1.88889 14.6667V2.38889H13.2222V14.6667C13.2222 14.9982 13.2798 15.3164 13.3837 15.6111H2.83333ZM17 14.6667C17 14.9171 16.9005 15.1574 16.7234 15.3345C16.5463 15.5116 16.306 15.6111 16.0556 15.6111C15.8051 15.6111 15.5648 15.5116 15.3877 15.3345C15.2106 15.1574 15.1111 14.9171 15.1111 14.6667V9.94444H17V14.6667Z"
        fill="#77d0a7"
      />
      <path
        d="M3.77777 4.27777H11.3333V6.16666H3.77777V4.27777ZM3.77777 8.05555H11.3333V9.94444H3.77777V8.05555ZM8.49999 11.8333H11.3333V13.7222H8.49999V11.8333Z"
        fill="#77d0a7"
      />
    </svg>
  );
}

export default NewsManagementIcon;
