import { Form, message } from "antd";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "components/uiComponents/CustomButton/CustomButton";
import CustomForm from "components/uiComponents/CustomForm/CustomForm";
import CustomFormItem from "components/uiComponents/CustomFormItem/CustomFormItem";
import CustomInput from "components/uiComponents/CustomInput/CustomInput";
import "../../auth/auth.less";
import useReactQuery from "hooks/useReactQuery";
import { API_CONFIG, API_ROUTES } from "api/api.config";
import { unauthenticatedRoutes } from "utils/constants/routes.constant";
import CookieService from "services/cookiesServices";
import notificationService from "services/notification.service";

function CheckPassword() {
  const [form] = Form.useForm();
  const [counter, setCounter] = React.useState(30);
  const navigate = useNavigate();
  const location = useLocation();
  let { token ,email} = location?.state;
  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  function handleClick() {
    navigate("/reset-password");
  }
  const onFinish = (body) => {
    const payload={
      code:body?.otp,
      token:token
    }
    checkEmail(payload)
  };

  const key = "otpmsg";

  const onSuccess =(data)=>{
    navigate(unauthenticatedRoutes.RESET_PASSWORD,{state:data});
      }


  const openMessage = (e) => {
    e.preventDefault();
    message.loading({
      content: "Sending OTP",
      key,
    });
    setTimeout(() => {
      message.success({
        content: "OTP Sent!",
        key,
        duration: 2,
      });
    }, 1500);
  };

  
  const {mutate:checkEmail,isLoading }=useReactQuery({
    queryType:"mutation",
    url:API_ROUTES.AUTH.CHECK_EMAIL,
    method:API_CONFIG.POST,
    queryOptions:{onSuccess}
    
  })
  const resendOtpSuccess =(data)=>{
notificationService.success("OTP sent succesfully")
  }

  const { mutate:resendOtp  }=useReactQuery({
    queryType:"mutation",
    url:API_ROUTES.AUTH.RESEND_OTP,
    method:API_CONFIG.POST,
    queryOptions:{onSuccess:resendOtpSuccess}
    
  })



  return (
    <div className="auth-sec login">
      <div className="container">
        <div className="auth-screen">
          <div className="auth-head">
            <h2>Verification</h2>
          </div>
          <CustomForm
            initialValues={{ remember: true }}
            name="basic"
            form={form}
            onFinish={onFinish}
            className="gx-signin-form gx-form-row0"
          >
            <CustomFormItem
              required={true}
              name="otp"
              label="Provide OTP"
              className="hide-form-label"
              validateTrigger="onBlur"
              rules={[

                {
                  required:true,
                  message:"OTP is required"


                },

                {
                  required: false,
                  message: "OTP is invalid",
                  pattern: new RegExp(/^(?=.*[0-9]).+$/),
                },
              
             
              ]}
            >
              <CustomInput placeholder="Enter OTP"  />
            </CustomFormItem>

            <div className="aside" style={{ marginTop: 0 }}>
              <p>Check your Email for OTP</p>
              <div className="forgot-pass">
                <CustomButton
                  type="primary"
                  className="gx-mb-0 link"
                  htmlType="submit"
                  // onClick={openMessage}
                  onClick={()=>{
                   const resendOtpData={
                      token:token,
                      email:email
                    }
                    resendOtp(resendOtpData)}}
                  disabled={counter >= 1 ? true : false}
                >
                  Resend OTP {counter >= 1 && `in ${counter}s`}
                </CustomButton>
              </div>
            </div>

            <CustomFormItem>
              <CustomFormItem>
                <CustomButton
                  type="primary"
                  className="gx-mb-0 btn"
                  htmlType="submit"
                  // onClick={handleClick}
                >
                  Verify
                </CustomButton>
              </CustomFormItem>
            </CustomFormItem>
          </CustomForm>
        </div>
      </div>
    </div>
  );
}

export default CheckPassword;
