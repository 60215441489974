import { Form, Checkbox } from "antd";
import React, { useContext, useEffect } from "react";
import {
  Link,
  redirect,
  useNavigate,
  useLocation,
  NavLink,
} from "react-router-dom";
import CustomButton from "components/uiComponents/CustomButton/CustomButton";
import CustomForm from "components/uiComponents/CustomForm/CustomForm";
import CustomFormItem from "components/uiComponents/CustomFormItem/CustomFormItem";
import CustomInput from "components/uiComponents/CustomInput/CustomInput";
import CustomPassword from "components/uiComponents/CustomPassword/CustomPassword";
import logo from "../../../assets/images/Logo.png";
import "../../auth/auth.less";
import useReactQuery from "hooks/useReactQuery";
import { API_CONFIG, API_ROUTES } from "api/api.config";
import CookieService from "services/cookiesServices";
import { STORAGE_KEYS } from "utils/constants/storage-keys.constant";
import utilService from "utils/Helpers/functions.helper";
import {
  authenticatedRoutes,
  unauthenticatedRoutes,
} from "utils/constants/routes.constant";
import AuthService from "services/auth.service";
import { useState } from "react";
import ProgressiveImage from "../../../components/ProgressiveImage/progressiveImage";
function Login() {
  const [form] = Form.useForm();
  const isAuthenticatedPlayer = AuthService.getPlayerId();
  const location = useLocation();
  const [check, setCheck] = useState(location.search ? true : false);

  // useEffect(()=>{

  //   OneSignal.isPushNotificationsEnabled().then(function(isEnabled) {
  //     if (isEnabled){
  //       setCheck(isEnabled)
  //       console.log("Push notifications are enabled!")
  //     }
  //     else {
  //       console.log("Push notifications are not enabled yet.");

  //       !location.search &&   OneSignal.showSlidedownPrompt({force:true});

  //     }
  //   });

  // },[])

  // useEffect(() => {
  //   OneSignal.on("popoverShown", function () {
  //       setCheck(true);
  //     });

  // }, [isAuthenticatedPlayer]);

  const onSuccess = (data) => {
    CookieService.set(STORAGE_KEYS.AUTH_TOKEN, data.accessToken);
    CookieService.set(STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken);
    CookieService.setObject(STORAGE_KEYS.USER_INFO, data.user);
    utilService.redirectTo(authenticatedRoutes.ADMIN_DASHBOARD);
  };
  const onFinish = async (data) => {
    const payload = {
      ...data,
      isRemember: data?.isRemember ? true : false,
    };
    mutate(payload);
  };
  const { mutate, isLoading } = useReactQuery({
    queryType: "mutation",
    url: API_ROUTES.AUTH.LOGIN,
    method: API_CONFIG.POST,
    queryOptions: { onSuccess },
  });

  return (
    <div>
      {/* {!check ? 
      <SplashScreen />
       : */}
      <div className='auth-sec login'>
        <div className='container'>
          <div className='auth-screen'>
            <div className='auth-head logo-wrapper'>
              <h2>Welcome Back!</h2>
              <ProgressiveImage cssClass={"App-logo"} src={logo} alt='logo' />
            </div>
            <CustomForm
              initialValues={{ remember: true }}
              name='basic'
              form={form}
              onFinish={onFinish}
              className='gx-signin-form gx-form-row0'
            >
              <CustomFormItem
                required={false}
                name='email'
                label='Email Address'
                className='hide-form-label'
                validateTrigger='onBlur'
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  { type: "email", message: "Email is not valid" },
                ]}
              >
                <CustomInput placeholder='Enter Email Address' />
              </CustomFormItem>
              <CustomFormItem
                required={false}
                validateTrigger='onBlur'
                className='hide-form-label'
                label='Password'
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
                name='password'
              >
                <CustomPassword type='password' placeholder='Enter password' />
              </CustomFormItem>
              <div className='aside'>
                <CustomFormItem name='isRemember' valuePropName='checked'>
                  <Checkbox>Remember me</Checkbox>
                </CustomFormItem>
                <div className='forgot-pass'>
                  <Link
                    className='link'
                    to={unauthenticatedRoutes.FORGOT_PASSWORD}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <CustomFormItem>
                <CustomFormItem>
                  <CustomButton
                    loading={isLoading}
                    type='primary'
                    className='gx-mb-0 btn'
                    htmlType='submit'
                  >
                    Login
                  </CustomButton>
                </CustomFormItem>
              </CustomFormItem>
            </CustomForm>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <NavLink to={"/terms-condition"}>Terms and Conditions</NavLink>
              <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </div>
  );
}

export default Login;
