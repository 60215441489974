import React, { memo, useState, useEffect, useRef } from "react";
import { Layout, Modal } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import CustomHeading from "../../components/uiComponents/CustomHeading/CustomHeading";
import NotificationIcons from "assets/all-clevis-svg-files/notification";
import OneSignal from "react-onesignal";
import "./header.less";
import {
  faEdit,
  faTrashAlt,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import utilService from "utils/Helpers/functions.helper";
import {
  authenticatedRoutes,
  unauthenticatedRoutes,
} from "utils/constants/routes.constant";
import { notificationsData } from "../../utils/data/notification.data";
import { useInfiniteQuery } from "@tanstack/react-query";
import { API_CONFIG, API_ROUTES } from "api/api.config";
import { request } from "api/api-call-handler";
import CookieService from "services/cookiesServices";
import { STORAGE_KEYS } from "utils/constants/storage-keys.constant";
import moment from "moment";
import { io } from "socket.io-client";
const { Header } = Layout;
const TopHeader = (props) => {
  // const socket = io(process.env.REACT_APP_HTTP_URL, {
  //   transports: ["websocket"], // Use only WebSocket transport
  //   query: {
  //     EIO: 4,
  //     auth: `${CookieService.get(STORAGE_KEYS.AUTH_TOKEN)}`,
  //     // Set the EIO version to 4
  //   },

  //   // auth: {
  //   //   token: `${CookieService.get(STORAGE_KEYS.AUTH_TOKEN)}`,
  //   // },
  // });
  const [notificationToggle, setNotificationToggle] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const toggleRef = useRef(null);
  const { info, confirm } = Modal;
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  // const socket = utilService.socketConnection();
  useEffect(() => {
    props?.socket.on("connect", () => {
      console.log("ID : ", props?.socket.id);
    });
    props?.socket.on("disconnect", () => {});
    props?.socket.on("auth failed", (data) =>
      console.log("AUTH FAILED : ", data)
    );
    props?.socket.on(STORAGE_KEYS.NOTIFICATION, (message) => {
      console.log("Received message from server:", message);
      const data = {
        ...message?.notification,
        createdAt: moment().format("YYYY-MM-DD"),
      };
      // notificationsData.push(message)
      setNotifications((previousNoti) => [data, ...previousNoti]);
      setNotificationCheck(true);
      CookieService.set(STORAGE_KEYS.NOTIFICATION_COUNT, true);
      //   })
      // Handle the received message from the server
      // For example, update the UI with the message
    });
    // return () => {
    //   console.log("disconnect");
    //   props?.socket.disconnect();
    // };
  }, []);

  useEffect(() => {
    // add event listener to document object
    document.addEventListener("click", handleClickOutside);

    return () => {
      // remove event listener when component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [notificationCheck, setNotificationCheck] = useState(
    CookieService.get(STORAGE_KEYS.NOTIFICATION_COUNT) ?? false
  );

  const { data: listingData, ...Meta } = useInfiniteQuery(
    ["NOTIFICATIONS", params],
    async ({ pageParam = 0 }) => {
      const { data } = await request({
        url: API_ROUTES.NOTIFICATION.NOTIFICATION_LISTING,
        params: { ...params, page: Number(pageParam) + 1, limit: 10 },
        method: API_CONFIG.GET,
      });

      return data;
    },

    {
      select: utilService.normalizeInfiniteQueryData,
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.data?.length < 10 ? undefined : Number(pages.length);
      },
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setNotifications(listingData);
  }, [listingData]);

  const handleClickOutside = (event) => {
    if (toggleRef.current && !toggleRef.current.contains(event.target)) {
      // close toggle if clicked outside
      setNotificationToggle(false);
    }
  };

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const TitleShow = () => {
    const a = pathname.split("/")[1];
    var newStr = a.replace(/-/g, " ");
    return newStr == "settings"
      ? "Account Settings"
      : newStr == ""
      ? "Dashboard"
      : newStr;
  };

  return (
    <>
      <Header className="gx-pr-0 topbar-sec">
        <div className="user-profile gx-d-flex gx-align-items-center gx-justify-content-center gx-position-relative">
          <h1 className="page-title">{TitleShow()}</h1>
          <div
            ref={toggleRef}
            className="user-profile__notification gx-position-relative"
            onClick={() => {
              setNotificationCheck(false);
              CookieService.set(STORAGE_KEYS.NOTIFICATION_COUNT, false);
              setNotificationToggle(!notificationToggle);
            }}
          >
            <div className="user-profile__notification-icon">
              {/* <img src={notificationIcon} alt="notificationIcon" /> */}
              {/* {notificationIcon} */}
              <NotificationIcons />
            </div>
            {notificationCheck && (
              <span>
                {/* {CookieService.get(STORAGE_KEYS.NOTIFICATION_COUNT) ?? notificationCount} */}
              </span>
            )}
          </div>

          <div
            onScroll={(e) => utilService.handleFetchOnScroll(e, Meta)}
            className={`user-profile__notification__dropdown notifications ${
              notificationToggle ? "active" : ""
            }`}
          >
            <div className="head">
              <CustomHeading className="sec-title">Notifications</CustomHeading>
            </div>

            <ul className="notification-list">
              {(notifications || []).map((item, ind) => {
                return (
                  <>
                    {" "}
                    <li
                      onClick={() =>
                        navigate(authenticatedRoutes.USER_MANAGEMENT)
                      }
                      // onClick={()=>navigate(authenticatedRoutes.NOTIFICATIONS,{state:item}
                      // onClick={()=>notificationModal(item)}
                    >
                      <Link>
                        {item?.title}
                        <span>{item?.body}</span>
                        <p>
                          <FontAwesomeIcon icon={faClock} />{" "}
                          {moment(item?.createdAt).format("YYYY-MM-DD")}
                        </p>
                      </Link>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </Header>
    </>
  );
};

export default memo(TopHeader);
